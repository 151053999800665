<template>
  <div class="card-wrapper">
    <router-link
      :to="{ name: 'influencer', params: { id: influencer.uuid } }"
      tag="a"
    >
      <div class="card-img">
        <img
          v-show="imageLoaded"
          ref="avatar"
          @load="imageLoaded = true"
          :src="influencer.user.avatar_url"
        />
        <v-skeleton-loader
          v-show="!imageLoaded"
          :loading="true"
          class="card-img"
          type="image"
          height="248px"
        ></v-skeleton-loader>
      </div>
      
    </router-link>
    <div class="card-info">
      <!-- expired at -->
      <expired-timer v-if="timer" template="list" :campaignInfluencer="timer" />
      <div class="card-influencer-info">
        <img
          class="card-influencer-country"
          :src="'/img/flags/' + influencer.country.flag" />
        <span class="card-influencer-name">
          {{ influencer.user.first_name }} {{ influencer.user.last_name }}
        </span>
        <span class="card-influencer-add">
          {{ influencer.gender === "MALE" ? "♂" : "♀" }} {{ influencer.birthday | date_to_age }} yo
        </span>
      </div>
      <div class="card-channel-info">
        <v-icon class="card-channel-icon">fab fa-instagram</v-icon>
        <span>{{ followers | local_numbers }} followers</span>
      </div>
    </div>
    <div
      v-if="$slots.footer"
      class="card-actions"
    >
      <slot name="footer"></slot>
    </div>
    <v-flex class="card-action-top-right p-2 cursor-pointer" v-if="isBusiness">
      <favorite-influencer
        :influencer="influencer"
        ref="favoriteComponent"
      ></favorite-influencer>
    </v-flex>
    <v-flex
      class="card-action-top-right pt-2 pr-3 cursor-pointer"
      v-if="isModerator && connection_id"
    >
      <v-icon color="red" medium @click="deleteInfluencer()"
        >fal fa-times</v-icon
      >
    </v-flex>
    <v-flex class="card-badge p-2">
      <v-badge color="badges" v-if="isNew">
        <template v-slot:badge> New </template>
      </v-badge>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import date_to_age from "@/helpers/filters/date_to_age";
import first_letter from "@/helpers/filters/first_letter";
import FavoriteInfluencer from "../inputs/FavoriteInfluencer";
import ExpiredTimer from "@/components/common/other/ExpiredTimer";

export default {
  components: {
    FavoriteInfluencer,
    ExpiredTimer,
  },
  props: {
    influencer: {
      required: true,
    },
    connection_id: {
      required: false,
    },
    flat: {
      required: false,
      type: Boolean,
      default: false,
    },
    timer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isNew: null,
      imageLoaded: false,
    };
  },
  filters: { date_to_age, local_numbers, first_letter },
  computed: {
    ...mapGetters("core/auth", ["isBusiness", "isModerator"]),
    followers() {
      return this.influencer.channels.reduce(
        (accumulator, entry) => accumulator + entry.followers,
        0
      );
    },
  },
  methods: {
    updateFavorite(value) {
      this.influencer.is_favorite = value;
      this.$refs.favoriteComponent.updateState(value);
    },
    updateNew() {
      const today = Date.parse(new Date());
      const created_at = Date.parse(this.influencer.created_at);
      this.isNew = today - created_at < 2629746000 ? true : false;
    },
    deleteInfluencer() {
      this.$emit("delete", this.connection_id);
    },
  },
  mounted() {
    this.updateNew();
  },
};
</script>
<style lang="scss" scoped>
.card-wrapper {
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
  border: 1px solid rgb(234, 236, 240);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 248px;
  height: 410px;

  .card-img {
    display: block;
    height: 248px;

    & > * {
      width: 100%;
      height: 100%; 
      object-fit: cover;
    }
  }

  .card-info {
    display: flex;
    flex-direction: column;

    .card-influencer-info {
      display: flex;
      align-items: center;
      border-top: 1px solid rgb(234, 236, 240);
      border-bottom: 1px solid rgb(234, 236, 240);
      padding: 10px;

      .card-influencer-country {
        width: 16px;
        margin-right: 10px;
      }

      .card-influencer-name {
        font-weight: bold;
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .card-influencer-add {
        white-space: nowrap;
      }
    }

    .card-channel-info {
      display: flex;
      align-items: center;
      padding: 5px 10px;

      .card-channel-icon {
        margin-right: 10px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .card-actions {
    padding: 0 10px;

    & > button {
      margin: 5px 0;
      border-color: lightgrey;
    }
  }

  .card-badge {
    position: absolute;
    left: 7px;
    top: 3px;
  }

  .card-action-top-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
